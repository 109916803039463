:root {
  --dt-row-selected: 2, 117, 216;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: #212529;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  box-sizing: border-box;
  content: "";
  border: 5px solid #0000;
  border-left: 10px solid #00000080;
  border-right-width: 0;
  display: inline-block;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  border: 5px solid #0000;
  border-top: 10px solid #00000080;
  border-bottom-width: 0;
}

table.dataTable tfoot:empty {
  display: none;
}

html.dark table.dataTable td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable td.dt-control:before, :root[data-theme="dark"] table.dataTable td.dt-control:before {
  border-left-color: #ffffff80;
}

html.dark table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top-color: #ffffff80;
  border-left-color: #0000;
}

div.dt-scroll {
  width: 100%;
}

div.dt-scroll-body thead tr, div.dt-scroll-body tfoot tr {
  height: 0;
}

div.dt-scroll-body thead tr th, div.dt-scroll-body thead tr td, div.dt-scroll-body tfoot tr th, div.dt-scroll-body tfoot tr td {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.dt-scroll-body thead tr th div.dt-scroll-sizing, div.dt-scroll-body thead tr td div.dt-scroll-sizing, div.dt-scroll-body tfoot tr th div.dt-scroll-sizing, div.dt-scroll-body tfoot tr td div.dt-scroll-sizing {
  height: 0 !important;
  overflow: hidden !important;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before {
  content: "▲";
  content: "▲" / "";
  display: block;
  position: absolute;
  bottom: 50%;
}

table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  content: "▼";
  content: "▼" / "";
  display: block;
  position: absolute;
  top: 50%;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > th.dt-ordering-asc, table.dataTable thead > tr > th.dt-ordering-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc, table.dataTable thead > tr > td.dt-ordering-asc, table.dataTable thead > tr > td.dt-ordering-desc {
  padding-right: 30px;
  position: relative;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  width: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .125;
  font-size: .8em;
  line-height: 9px;
  left: 0;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
}

table.dataTable thead > tr > th.dt-orderable-asc:hover, table.dataTable thead > tr > th.dt-orderable-desc:hover, table.dataTable thead > tr > td.dt-orderable-asc:hover, table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline-offset: -2px;
  outline: 2px solid #0000000d;
}

table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .6;
}

table.dataTable thead > tr > th.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > th.sorting_asc_disabled span.dt-column-order:before, table.dataTable thead > tr > td.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > td.sorting_asc_disabled span.dt-column-order:before {
  display: none;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

div.dt-scroll-body > table.dataTable > thead > tr > th, div.dt-scroll-body > table.dataTable > thead > tr > td {
  overflow: hidden;
}

:root.dark table.dataTable thead > tr > th.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > th.dt-orderable-desc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid #ffffff0d;
}

div.dt-processing {
  text-align: center;
  z-index: 10;
  width: 200px;
  margin-top: -22px;
  margin-left: -100px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dt-processing > div:last-child {
  width: 80px;
  height: 15px;
  margin: 1em auto;
  position: relative;
}

div.dt-processing > div:last-child > div {
  background: #0275d8;
  background: rgb(var(--dt-row-selected));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

div.dt-processing > div:last-child > div:first-child {
  animation: .6s infinite datatables-loader-1;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(2) {
  animation: .6s infinite datatables-loader-2;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(3) {
  animation: .6s infinite datatables-loader-2;
  left: 32px;
}

div.dt-processing > div:last-child > div:nth-child(4) {
  animation: .6s infinite datatables-loader-3;
  left: 56px;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th, table.dataTable td {
  box-sizing: border-box;
}

table.dataTable th.dt-type-numeric, table.dataTable th.dt-type-date, table.dataTable td.dt-type-numeric, table.dataTable td.dt-type-date {
  text-align: right;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable th.dt-empty, table.dataTable td.dt-empty {
  text-align: center;
  vertical-align: top;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td, table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable.table {
  clear: both;
  border-spacing: 0;
  max-width: none;
  margin-bottom: 0;
}

table.dataTable.table.table-striped > tbody > tr:nth-of-type(odd), table.dataTable.table > tbody > tr {
  background-color: #0000;
}

table.dataTable.table > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px #0275d8;
  box-shadow: inset 0 0 0 9999px rgb(var(--dt-row-selected));
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable.table > tbody > tr.selected a {
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable.table.table-striped > tbody > tr:nth-of-type(odd) > * {
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .05);
}

table.dataTable.table.table-striped > tbody > tr:nth-of-type(odd).selected > * {
  box-shadow: inset 0 0 0 9999px #0275d8f2;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .95);
}

table.dataTable.table.table-hover > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .075);
}

table.dataTable.table.table-hover > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0275d8f9;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .975);
}

div.dt-container div.dt-layout-start > :not(:last-child) {
  margin-right: 1em;
}

div.dt-container div.dt-layout-end > :not(:first-child) {
  margin-left: 1em;
}

div.dt-container div.dt-layout-full {
  width: 100%;
}

div.dt-container div.dt-layout-full > :only-child {
  margin-left: auto;
  margin-right: auto;
}

div.dt-container div.dt-layout-table > div {
  display: block !important;
}

@media screen and (width <= 767px) {
  div.dt-container div.dt-layout-start > :not(:last-child) {
    margin-right: 0;
  }

  div.dt-container div.dt-layout-end > :not(:first-child) {
    margin-left: 0;
  }
}

div.dt-container > div.row {
  margin-bottom: .5rem;
}

div.dt-container > div.row:last-child {
  margin-bottom: 0;
}

div.dt-container div.dt-length label {
  text-align: left;
  white-space: nowrap;
  margin-bottom: 0;
  font-weight: normal;
}

div.dt-container div.dt-length select {
  width: auto;
  margin-right: .5em;
  display: inline-block;
}

div.dt-container div.dt-search label {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 0;
  font-weight: normal;
}

div.dt-container div.dt-search input {
  width: auto;
  margin-left: .5em;
  display: inline-block;
}

div.dt-container div.dt-info {
  white-space: nowrap;
}

div.dt-container div.dt-paging {
  margin: 0;
}

div.dt-container div.dt-paging ul.pagination {
  flex-wrap: wrap;
  margin: 0;
}

div.dt-container div.dt-processing {
  text-align: center;
  width: 200px;
  margin-top: -26px;
  margin-left: -100px;
  padding: 1em 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dt-container div.dt-scroll-body {
  border-bottom: 1px solid #dee2e6;
}

div.dt-container div.dt-scroll-body table, div.dt-container div.dt-scroll-body tbody > tr:last-child > * {
  border-bottom: none;
}

div.dt-scroll-head table.dataTable {
  margin-bottom: 0 !important;
}

div.dt-scroll-body > table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dt-scroll-body > table thead .dt-orderable-asc:before, div.dt-scroll-body > table thead .dt-orderable-desc:after {
  display: none;
}

div.dt-scroll-body > table > tbody tr:first-child th, div.dt-scroll-body > table > tbody tr:first-child td {
  border-top: none;
}

div.dt-scroll-foot > .dt-scroll-footInner {
  box-sizing: content-box;
}

div.dt-scroll-foot > .dt-scroll-footInner > table {
  border-top: none;
  margin-top: 0 !important;
}

@media screen and (width <= 767px) {
  div.dt-container div.dt-length, div.dt-container div.dt-search, div.dt-container div.dt-info, div.dt-container div.dt-paging {
    text-align: center;
  }

  div.dt-container div.row {
    margin-bottom: 0;
  }

  div.dt-container div.row > * {
    margin-bottom: .5rem;
  }

  div.dt-container div.dt-paging ul.pagination {
    justify-content: center !important;
  }
}

table.dataTable.table-sm > thead > tr th.dt-orderable-asc, table.dataTable.table-sm > thead > tr th.dt-orderable-desc, table.dataTable.table-sm > thead > tr th.dt-ordering-asc, table.dataTable.table-sm > thead > tr th.dt-ordering-desc, table.dataTable.table-sm > thead > tr td.dt-orderable-asc, table.dataTable.table-sm > thead > tr td.dt-orderable-desc, table.dataTable.table-sm > thead > tr td.dt-ordering-asc, table.dataTable.table-sm > thead > tr td.dt-ordering-desc {
  padding-right: 20px;
}

table.dataTable.table-sm > thead > tr th.dt-orderable-asc span.dt-column-order, table.dataTable.table-sm > thead > tr th.dt-orderable-desc span.dt-column-order, table.dataTable.table-sm > thead > tr th.dt-ordering-asc span.dt-column-order, table.dataTable.table-sm > thead > tr th.dt-ordering-desc span.dt-column-order, table.dataTable.table-sm > thead > tr td.dt-orderable-asc span.dt-column-order, table.dataTable.table-sm > thead > tr td.dt-orderable-desc span.dt-column-order, table.dataTable.table-sm > thead > tr td.dt-ordering-asc span.dt-column-order, table.dataTable.table-sm > thead > tr td.dt-ordering-desc span.dt-column-order {
  right: 5px;
}

div.dt-scroll-head table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dt-container > div.row {
  margin: 0;
}

div.table-responsive > div.dt-container > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dt-container > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}
/*# sourceMappingURL=site.8ba636ee.css.map */
